<template>
  <div class="privacy-backdrop">
    <div class="news-header">
      <back-navigation currentName="Privacy Policy" backName="Focus on the harvest" />
    </div>

    <a href="/"><img class="auth-logo" src="@/assets/FOTH_logotyp_Roboto.png" alt="Focus on the Harvest"></a>
    <div style="text-align: left">
      <p style="font-weight: bold; font-size: 24px">Personuppgifter</p>
      <p>Den 25 maj 2018 trädde den nya europeiska dataskyddsförordningen GDPR i kraft och ersätter den svenska personuppgiftslagen. I och med detta önskar vi informera hur vi hanterar personuppgifter.</p>
      <p>
        <span style="font-weight: bold;">HUR HAR VI FÅTT DINA UPPGIFTER?</span><br>
        Focus on the Harvest köper inte in personuppgifter från andra företag. Finns du med i våra register är det någon av följande skäl:
      </p>
      <ul>
        <li>Du är månatlig givare</li>
        <li>Du ger eller har gett gåvor till Focus on the Harvest tidigare</li>
        <li>Du har fyllt i lista för nyhetsbrev under möte/samling</li>
        <li>Du har varit i kontakt med Focus on the Harvest av annan anledning där uppgifter lämnats</li>
      </ul>
      <p>
        <span style="font-weight: bold;">VILKA PERSONUPPGIFTER BEHANDLAR VI?</span><br>
        För de flesta personer i vårt register har vi endast namn, adress och e-post. Vi har också gåvohistorik (vad du har gett och till vilket ändamål) och viss kontakthistorik, till exempel om du ringer och vill avsluta ett tagande.
      </p>
      <p>
        <span style="font-weight: bold;">HUR ANVÄNDER VI PERSONUPPGIFTERNA?</span><br>
        Vi använder dina uppgifter
      </p>
      <ul>
        <li>För att uppehålla kontakten med dig som givare, detta kan innefatta ex, insamlingsbrev, inbjudningskort till samlingar, rapporter från projekt men även personlig kontakt via brev, telefon eller e-post.</li>
        <li>För att registrera och ta emot gåvor.</li>
        <li>I vår bokföring</li>
        <li>I fakturor (om du beställt någon tjänst av oss , sponsorskap på hemsidan)</li>
      </ul>
      <p>
        <span style="font-weight: bold;">DELAR VI DINA UPPGIFTER MED NÅGON?</span><br>
        Vi delar inte dina uppgifter med någon.
      </p>
      <p>
        <span style="font-weight: bold;">VAD KAN DU GÖRA?</span><br>
        Du kan begränsa hur vi använder dina uppgifter, till exempel avstå från insamlingsbrev och nyhetsbrev via e-post.
      </p>
      <p>
        Kontaktperson på Focus on the Harvest<br>
        Mats Wennergrund, <a href="mailto:mats@focusontheharvest.net" class="text-hoverable" style="text-decoration: underline !important">mats@focusontheharvest.net</a>
      </p>
    </div>
  </div>
</template>

<script>
import BackNavigation from '../components/BackNavigation.vue'
export default {
  components: {
    BackNavigation
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
.privacy-backdrop {
  margin: 100px auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 800px; 
  max-width: 90%; 
  box-sizing: border-box;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.075);
}

.privacy-backdrop > div > ul {
  margin-bottom: 30px;
}
.privacy-backdrop > div > ul > li {
  color: black !important;
  font-size: 20px;
  margin: 0 30px;
}

.privacy-backdrop > div > p {
  margin-bottom: 30px;
}

.auth-logo {
  min-width: none;
  max-width: 200px;
  width: 100%;
  margin: 20px auto;
}
</style>